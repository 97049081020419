import classNames from "classnames";
import { formatDate } from "utils/transform/formatDate";
import styles from "./styles.module.scss";

interface IProps {
  timeSlot: string;
  paymentType: "useInsurance" | "selfPay";
  slotTaken: boolean;
  appointmentType: "phone" | "video" | null;
}

function ConfirmModal({
  timeSlot,
  paymentType,
  slotTaken,
  appointmentType,
}: IProps) {
  const appointmentName = formatAppointmentConfirmModal(
    appointmentType,
    paymentType
  );

  const phoneButVideo =
    appointmentType === "phone" && paymentType === "useInsurance";

  if (phoneButVideo) {
    return (
      <>
        <div className={styles.appointmentConfirmationDetails}>
          <Header phoneButVideo={phoneButVideo} slotTaken={slotTaken} />
          <Details
            appointmentName={appointmentName}
            paymentType={paymentType}
            timeSlot={timeSlot}
            phoneButVideo={phoneButVideo}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.appointmentConfirmationDetails}>
        <Header phoneButVideo={phoneButVideo} slotTaken={slotTaken} />
        <Details
          appointmentName={appointmentName}
          paymentType={paymentType}
          timeSlot={timeSlot}
          phoneButVideo={phoneButVideo}
        />
      </div>
    </>
  );
}

export default ConfirmModal;

// utils
const formatAppointmentConfirmModal = (
  appointmentType: "phone" | "video" | null,
  paymentType: "useInsurance" | "selfPay"
) => {
  if (!appointmentType) return null;

  if (
    (appointmentType === "phone" && paymentType === "useInsurance") ||
    appointmentType === "video"
  )
    return "Video appointment";
  return "Phone appointment";
};

interface IDetails {
  appointmentName: "Video appointment" | "Phone appointment" | null;
  phoneButVideo: boolean;
}

const Header = ({
  slotTaken,
  phoneButVideo = false,
}: { phoneButVideo: boolean } & Pick<IProps, "slotTaken">) => {
  if (slotTaken)
    return (
      <p>
        Sorry! This time slot is no longer available. Please select a different
        one,
      </p>
    );

  if (phoneButVideo)
    return (
      <p
        className={classNames(
          styles.confirmationNote,
          styles.phoneButInsurance
        )}
      >
        <span>Note:</span> Patients paying with insurance cannot have a phone
        appointment. We’ve switched your booking to a video appointment.
      </p>
    );
  return null;
};

function yearFix(dateString: string) {
  const [year, month, day] = dateString.split("-");
  const newDateString = `${month}-${day}-${year}`;
  return newDateString;
}

function timeFix(timeString: string) {
  const [hourFromString, minuteampm] = timeString.split(":");
  const minute = minuteampm.slice(0, -2);
  const ampm = minuteampm.slice(-2);
  let newHour;
  const hour: number = +hourFromString;

  if (ampm === "pm" && hour !== 12) {
    newHour = hour + 12;
  } else {
    newHour = hour < 10 ? `0${hour}` : hour;
  }
  const fixedTime = `${newHour}:${minute}`;
  return fixedTime;
}

const Details = ({
  appointmentName,
  paymentType,
  timeSlot,
  phoneButVideo,
}: IDetails & Pick<IProps, "paymentType" | "timeSlot">) => {
  const paymentTypeDetails =
    paymentType === "useInsurance" ? "Paid with insurance" : "Self pay";

  const fixedTime = timeFix(timeSlot.split("@")[0]);
  const createDateTimeString = `${timeSlot.split("@")[1]} ${fixedTime}`;
  console.log(`fixedTime:${fixedTime}`);
  console.log(`createDateTimeString:${createDateTimeString}`);
  return (
    <>
      {appointmentName && (
        <p className={classNames({ [styles.videoRedText]: phoneButVideo })}>
          {appointmentName}
        </p>
      )}
      <p>{formatDate(new Date(createDateTimeString))}</p>
      <p>{timeSlot.split("@")[0]}</p>
      <p>{paymentTypeDetails}</p>
    </>
  );
};
