import { useEffect, useRef, useState } from "react";
import Loading from "pages/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import userService from "services/userService";
import bookingService from "services/bookingService";
import { appointmentReserved, dashboardAppointments } from "routes/paths";
import { AxiosResponse } from "axios";
import { readStripeBookingData } from "utils/appointmentUtils";
import { getItem, removeItem } from "utils/localStorageUtils";
import TagManager from "react-gtm-module";

import { useAppSelector } from "redux/store";
import { selectFirstBookingAppointmentStep } from "redux/bookAppointmentFlow.slice";
import { ServicePrice } from "components/forms/Payment";

const stripeSuccessUrl = appointmentReserved;
export const stripeCancelUrl = `${origin}/book-appointment?paymentError=true`;
const stripePageError = `${origin}/book-appointment?unknownError=true`;
const slotReserved = `${origin}/${dashboardAppointments}?paymentError=true&slotReserved=true`;

export enum CheckoutSessionStatusEnum {
  OPEN = "open",
  EXPIRED = "expired",
  COMPLETE = "complete",
}

export enum CheckoutSessionPaymentStatusEnum {
  PAID = "paid",
  UNPAID = "unpaid",
}

interface IStripeStatus {
  status: CheckoutSessionStatusEnum;
  paymentStatus: CheckoutSessionPaymentStatusEnum;
}

const { getStripeStatus } = userService;
const { bookAppointment } = bookingService;

const Stripe = () => {
  const navigate = useNavigate();
  const ref = useRef(false);
  const [text, setText] = useState<string>("Processing payment");
  const stripePageProtect = Boolean(getItem("stripe_session_id"));

  const firstStep = useAppSelector(selectFirstBookingAppointmentStep);

  const handle = () => {
    getStripeStatus()
      .then(({ data }: AxiosResponse<IStripeStatus>) => {
        if (
          data.status === CheckoutSessionStatusEnum.COMPLETE &&
          data.paymentStatus === CheckoutSessionPaymentStatusEnum.PAID
        ) {
          setText("Booking appointment");
          return Promise.resolve();
        }
        return Promise.reject(Error("Stripe payment error"));
      })
      .then(() => readStripeBookingData())
      .then((data) => {
        const appointmentTypeFull = data.appointmentType;
        const appointmentType = appointmentTypeFull.split("-")[2].trim();
        userService
          .getArticlePrice(appointmentTypeFull)
          .then(({ data: priceData }: AxiosResponse<ServicePrice>) => {
            const tagManagerArgs = {
              dataLayer: {
                event: "event",
                eventProps: {
                  category: "Booking",
                  action: "Complete",
                  label: appointmentType,
                  value: priceData.price,
                },
              },
            };
            TagManager.dataLayer(tagManagerArgs);
          });

        return bookAppointment(data);
      })
      .then(() => {
        removeItem("stripe_session_id");
      })
      .then(() => navigate(stripeSuccessUrl))
      .catch((err) => {
        if (err?.response?.status === 409) {
          window.location.assign(slotReserved);
          return;
        }
        window.location.assign(stripePageError);
      });
  };

  useEffect(() => {
    if (!ref.current && stripePageProtect) {
      ref.current = true;
      handle();
    }
  }, [stripePageProtect]);

  if (!stripePageProtect)
    return <Navigate to={`/${dashboardAppointments}`} replace={true} />;

  return <Loading text={text} customShell />;
};

export default Stripe;
