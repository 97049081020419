import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "styles/theme";
import { Helmet } from "react-helmet";
import AppRouter from "./routes/AppRouter";

function App(): JSX.Element {
  const disableIndexing = process.env.REACT_APP_ENV !== "production";

  return (
    <>
      <Helmet>
        {disableIndexing && <meta name="robots" content="noindex"></meta>}
      </Helmet>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <AppRouter />
        </StyledEngineProvider>
      </ThemeProvider>{" "}
    </>
  );
}

export default App;
