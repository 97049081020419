import { Registration } from "redux/registration.slice";

const createNewUserFromState = (registrationState: Registration) => {
  const { firstStep, secondStep, thirdStep, adSource } = registrationState;

  const dob = firstStep.dateOfBirth.replaceAll("/", "-");
  const phoneNo = [
    thirdStep.phoneNumber,
    thirdStep.secondaryPhoneNumber,
  ].filter((val) => !!val);

  return {
    email: secondStep.email,
    password: secondStep.password,
    firstName: firstStep.firstName,
    lastName: firstStep.lastName,
    dateOfBirth: dob,
    sexAtBirth: firstStep.gender,
    gender: firstStep.gender,
    phoneNo,
    zipCode: thirdStep.zip,
    city: thirdStep.city,
    state: thirdStep.state,
    addressLine: [thirdStep.address],
    ad_source: adSource.ad_source,
    gclid: adSource.gclid,
    utm_source: adSource.utm_source,
    utm_medium: adSource.utm_medium,
    utm_campaign: adSource.utm_campaign,
    utm_content: adSource.utm_content,
  };
};

export default createNewUserFromState;
