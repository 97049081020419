import { AxiosResponse } from "axios";
import { IDocumentDTO } from "components/forms/Payment";
import {
  appointmentsData,
  documentPost,
  getAppointmentData,
  getStripeStatusPath,
  insurance,
  profileDocuments,
  tags,
  user,
} from "routes/paths";
import { getItem } from "utils/localStorageUtils";
import { httpGet, httpPatch, httpPost } from "./httpClient";

interface IStripeInit {
  appointmentType: string | null;
  successUrl: string;
  cancelUrl: string;
}

const register = async (user: {}) => {
  try {
    const res = await httpPost("user/new-user", user);
    return res;
  } catch (error) {
    console.error("error: ", error);
    return error;
  }
};

const login = async (credentials: { email: string; password: string }) =>
  httpPost("user/authenticate-user", credentials);

const uploadDocument = (document: IDocumentDTO): Promise<AxiosResponse> => {
  const transferObject = {
    document_type: document.type,
    file: document.file,
    custom_title: document.title,
    side: document.side,
    rank: 1,
    insuranceId: document.insuranceId,
  };

  return httpPost(documentPost, transferObject, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getInsuranceDetails = async () => httpGet(insurance);
const getUserDetails = async () => httpGet(user);
const getTags = async () => httpGet(tags);
const checkAndUpdateUserTags = async () =>
  await httpPatch(tags).then(() => getTags());

const getAppointments = async () => httpGet(appointmentsData);
const getAppointment = async (id: string) => httpGet(getAppointmentData(id));

// stripe
const initiateStripe = async (url: string, params: IStripeInit) =>
  httpPost(url, params);

const sendPasswordResetEmail = async (email: string) =>
  httpPost("user/password-reset-trigger", { email });

const saveNewPassword = async (token: any, newPassword: string) =>
  httpPost("user/password-reset", { token, newPassword });

const saveMedicalData = async (data: any) =>
  httpPost("user/medical-info", data);

const createPaymentUrl = async () => {
  const sessionIdObject = getItem("stripe_session_id");
  const sessionId = sessionIdObject?.session_id;

  if (!sessionId) return Promise.reject(Error("session id doesnt exist"));
  return getStripeStatusPath(sessionId);
};

const getStripeStatus = async () =>
  createPaymentUrl().then((path: string) => httpGet(path));

const getArticlePrice = async (appointmentTypeName: string) =>
  httpGet(`stripe-product/price/?appointmentType=${appointmentTypeName}`);

const getAllMessages = async () => httpGet("message");

const sendMessage = async (body: string) => httpPost("message", { body });

const changePassword = (oldPassword: string, newPassword: string) =>
  httpPatch("user/password", {
    oldPassword,
    newPassword,
  });

const editContactDetails = (contactDetails: {}) =>
  httpPatch("user/contact-details", contactDetails);

const changeEmail = (newEmail: string, password: string) =>
  httpPatch("user/email", { newEmail, password });

const getUserProfileDocuments = async () => httpGet(profileDocuments);

const userService = {
  login,
  register,
  uploadDocument,
  getUserDetails,
  getInsuranceDetails,
  getTags,
  checkAndUpdateUserTags,
  getAppointments,
  getAppointment,
  initiateStripe,
  getStripeStatus,
  getArticlePrice,
  changePassword,
  editContactDetails,
  getUserProfileDocuments,
  changeEmail,
  sendPasswordResetEmail,
  saveNewPassword,
  saveMedicalData,
  getAllMessages,
  sendMessage,
};

export default userService;
