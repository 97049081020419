import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  selectThirdStep,
  saveThirdStep,
  userRegister,
  selectRegistrationLoading,
} from "redux/registration.slice";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import InputField from "components/ui/InputField";
import Checkbox from "components/ui/Checkbox";
import useValidate from "utils/validation";
import {
  VALIDATE_ADDRESS,
  VALIDATE_PHONE,
  VALIDATE_SECONDARY_PHONE,
  VALIDATE_CITY,
  VALIDATE_STATE,
  VALIDATE_ZIP,
} from "utils/validation/constants";
import classNames from "classnames";
import statesOptions from "utils/statesOptions";
import {
  formatToPhone,
  unwrapPhoneNumber,
} from "utils/transform/formatToPhone";
import client, { Events } from "services/EventEmitter";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";

interface Props {
  nextStep: (stepId: number) => void;
}

export default function ThidrStep({ nextStep }: Props) {
  const dispatch = useAppDispatch();
  const thirdStep = useAppSelector(selectThirdStep);
  const isLoading = useAppSelector(selectRegistrationLoading);
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState<string>(thirdStep.phoneNumber);
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState<string>(
    thirdStep.secondaryPhoneNumber
  );
  const [address, setAddress] = useState<string>(thirdStep.address);
  const [city, setCity] = useState<string>(thirdStep.city);
  const [state, setState] = useState<string>(thirdStep.state);
  const [zip, setZip] = useState<string>(thirdStep.zip);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const [isPhoneValid, phoneErrors] = useValidate(phoneNumber, VALIDATE_PHONE);
  const [isSecondaryPhoneValid, secondaryPhoneErrors] = useValidate(
    secondaryPhoneNumber,
    VALIDATE_SECONDARY_PHONE
  );
  const [isAddressValid, addressErrors] = useValidate(
    address,
    VALIDATE_ADDRESS
  );
  const [isCityValid, cityErrors] = useValidate(city, VALIDATE_CITY);
  const [isStateValid, stateErrors] = useValidate(state, VALIDATE_STATE);
  const [isZipValid, zipErrors] = useValidate(zip, VALIDATE_ZIP);

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    setHaveErrors(
      !isPhoneValid ||
        !isSecondaryPhoneValid ||
        !isAddressValid ||
        !isCityValid ||
        !isStateValid ||
        !isZipValid ||
        !acceptedTerms
    );
  }, [
    isPhoneValid,
    isSecondaryPhoneValid,
    isAddressValid,
    isCityValid,
    isStateValid,
    isZipValid,
    acceptedTerms,
  ]);

  const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unformatedPhoneNumber = unwrapPhoneNumber(e.target.value);
    setPhoneNumber(unformatedPhoneNumber);
  };

  const handleChangeSecondNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unformatedPhoneNumber = unwrapPhoneNumber(e.target.value);
    setSecondaryPhoneNumber(unformatedPhoneNumber);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAddress(e.target.value);

  const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCity(e.target.value);

  const handleChangeState = (selectedState: string) => setState(selectedState);

  const handleChangeZip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 5) return;
    setZip(value);
  };

  const handleContinue = async () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    dispatch(
      saveThirdStep({
        phoneNumber,
        secondaryPhoneNumber,
        address,
        city,
        state,
        zip,
      })
    );

    try {
      await dispatch(userRegister());
      navigate("/sign-up-complete");
    } catch (rejectedValueOrSerializedError) {}
  };

  return (
    <>
      <h2 className="FormComponent-title">Provide your contact details</h2>
      <p className="FormComponent-description">
        Should we need to get in touch with you about an appointment, we’ll use
        these details.
      </p>
      <div className={classNames({ [styles.loadingCover]: isLoading })}>
        <InputField
          value={formatToPhone(phoneNumber)}
          onChange={handleChangePhoneNumber}
          error={phoneErrors[0]}
          showError={showErrors}
          label="Phone number"
          id="phoneNumber"
        />
        {/* <InputField
          value={formatToPhone(secondaryPhoneNumber)}
          onChange={handleChangeSecondNumber}
          error={secondaryPhoneErrors[0]}
          showError={showErrors}
          label="Secondary phone number"
          required={false}
          id="secondaryPhoneNumber"
        /> */}
        <InputField
          value={address}
          onChange={handleChangeAddress}
          error={addressErrors[0]}
          showError={showErrors}
          label="Address line 1"
          id="address"
        />
        <InputField
          value={city}
          onChange={handleChangeCity}
          error={cityErrors[0]}
          showError={showErrors}
          label="Town/city"
          id="city"
        />
        <Dropdown
          initialValue={thirdStep.state}
          options={statesOptions}
          onChange={handleChangeState}
          label="State"
          error={stateErrors[0]}
          showError={showErrors}
        />
        <InputField
          value={zip}
          onChange={handleChangeZip}
          error={zipErrors[0]}
          showError={showErrors}
          label="Zip code"
          id="zip"
        />

        <Checkbox
          checked={acceptedTerms}
          onChange={setAcceptedTerms}
          errorMessage="You need to accept our terms & conditions and privacy policy to proceed"
          showError={showErrors}
          id="terms"
          description={termsLabel}
        />
      </div>

      <ActionsContainer>
        <Button
          text="Complete sign up"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
          isLoading={isLoading}
        />
      </ActionsContainer>
    </>
  );
}

const termsLabel = (
  <>
    I agree to Cloudwell Health’s
    <a
      href="https://cloudwellhealth.com/terms-of-use/"
      target="_blank"
      rel="noreferrer"
    >
      terms & conditions
    </a>
    and
    <a
      href="https://cloudwellhealth.com/privacy-policy/"
      target="_blank"
      rel="noreferrer"
    >
      privacy policy
    </a>
    <span>.</span>
  </>
);
