import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppShell from "components/layout/AppShell";
import Login from "pages/Login";
import Register from "pages/Register";
import ResetPassword from "pages/ResetPassword";
import Home from "pages/Home";
import Dashboard from "pages/Dashboard";
import SignUpComplete from "pages/SignUpComplete";
import AppointmentReserved from "pages/AppointmentReserved";
import FlowPCP from "pages/FlowPCP";
import NotFound from "pages/NotFound";
import Appointments from "components/forms/@dashboard/Appointments";
import Documents from "components/forms/@dashboard/Documents";
import MedicalInformation from "components/forms/@dashboard/MedicalInformation";
import MedicalInformationComplete from "components/forms/@dashboard/MedicalInformation/MedicalInformationComplete";
import MyProfile from "components/forms/@dashboard/MyProfile";
import ProtectedRoute from "routes/ProtectedRoute";
import PublicOnlyRoute from "routes/PublicOnlyRoute";
import FlowBookingAppointment from "pages/FlowBookingAppointment";
import AppointmentDetails from "components/forms/@dashboard/Appointments/AppointmentDetails";
import Stripe from "components/stripe";
import Chat from "components/forms/@dashboard/Chat";
import { processingStripe } from "./paths";
import Redirect from "./Redirect";

function AppRouter(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppShell />}>
          {/* <Route
            path="/"
            element={<Redirect url="https://cloudwellhealth.com/" />}
          /> */}
          <Route path="/" element={<Home />} />
          {/* 📢📢 Public only routes 📢📢 */}
          <Route element={<PublicOnlyRoute />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
          </Route>
          {/* 🔒🔒 Protected routes 🔒🔒 */}
          <Route element={<ProtectedRoute />}>
            <Route path="sign-up-complete" element={<SignUpComplete />} />
            <Route
              path="appointment-reserved"
              element={<AppointmentReserved />}
            />
            <Route path="dashboard" element={<Dashboard />}>
              <Route index path="appointments" element={<Appointments />} />
              <Route path="appointments/:id" element={<AppointmentDetails />} />
              <Route path="documents" element={<Documents />} />
              <Route path="chat" element={<Chat />} />
              <Route path="profile" element={<MyProfile />} />
            </Route>
            {/* 🛣️🛣️ Flows 🛣️🛣️ */}
            <Route path="pcp" element={<FlowPCP />} />

            <Route
              path="medical-information"
              element={<MedicalInformation />}
            />
            <Route
              path="medical-information-complete"
              element={<MedicalInformationComplete />}
            />
          </Route>
          <Route element={<ProtectedRoute redirect="/login" />}>
            <Route
              path="book-appointment"
              element={<FlowBookingAppointment />}
            />
          </Route>

          {/* 4️⃣0️⃣4️⃣ Page */}
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path={processingStripe} element={<Stripe />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
