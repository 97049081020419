import { store } from "redux/store";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";
import { logout, setNewJwt } from "redux/auth.slice";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE;
const API_KEY = process.env.REACT_APP_API_KEY;

const httpClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "X-API-KEY": API_KEY as string,
  },
});

httpClient.interceptors.request.use(
  function (config: any | AxiosRequestConfig) {
    const { jwt } = store.getState().auth;

    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`;
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError<any>) {
    const originalConfig = error.config;

    if (
      error.response?.status === 401 &&
      error.response?.data.message === "Invalid token"
    ) {
      const { refreshToken } = store.getState().auth;
      const { dispatch } = store;

      if (refreshToken)
        return httpPost("user/refresh-token", {
          refreshToken,
        })
          .then((response: any) => {
            dispatch(setNewJwt({ jwt: response.data.jwtToken }));
            return httpClient(originalConfig);
          })
          .catch(() => dispatch(logout()));
    }

    return Promise.reject(error);
  }
);

const httpGet = (url: string, params?: {}) =>
  httpClient.get(`/${url}`, {
    params,
  });

const httpPost = (url: string, payload: object, config?: AxiosRequestConfig) =>
  httpClient.post(`/${url}`, payload, config);

const httpPatch = (
  url: string,
  payload?: object,
  config?: AxiosRequestConfig
) => httpClient.patch(`/${url}`, payload, config);

export { httpGet, httpPost, httpPatch };
