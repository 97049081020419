import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "components/layout/Header";
import client, { Events } from "services/EventEmitter";
import scrollToTop from "utils/scrollToTop";
import { disableScroll, enableScroll } from "utils/scrollUtils";

function AppShell(): JSX.Element {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    client.on(Events.SCROLL_TO_TOP, scrollToTop);
    client.on(Events.ENABLE_SCROLL, enableScroll);
    client.on(Events.DISABLE_SCROLL, disableScroll);
    return () => {
      client.remove(Events.SCROLL_TO_TOP, scrollToTop);
      client.remove(Events.ENABLE_SCROLL, enableScroll);
      client.remove(Events.DISABLE_SCROLL, disableScroll);
    };
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      scrollToTop();
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <Header />
      <div className="page-mt-container">
        <Outlet />
      </div>
    </>
  );
}

export default AppShell;
