import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, Location } from "react-router-dom";
import { ReactComponent as BackStep } from "assets/images/backStep.svg";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import appLogo from "assets/images/appLogo.svg";
import Button from "components/ui/Button";
import useAuth from "hooks/useAuth";
import { ReactComponent as ProfileIcon } from "assets/images/drawerProfileIcon.svg";
import getActiveForm from "utils/getActiveForm";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  selectRegistrationActiveStep,
  setRegistrationGoBack,
} from "redux/registration.slice";
import { selectPcpActiveStep, setPcpGoBack } from "redux/pcpFlow.slice";
import { logout } from "redux/auth.slice";
import ActionTooltip from "components/ui/ActionTooltip";
import client, { Events } from "services/EventEmitter";
import { flowAppointment } from "routes/paths";
import {
  selectBookingActiveStep,
  setBookingAppointmentGoBack,
} from "redux/bookAppointmentFlow.slice";
import { fetchUserTags, selectLoadingUserTags } from "redux/user.slice";
import Drawer from "./Drawer";
import styles from "./styles.module.scss";
import MobileFormNav from "./MobileFormNav";

const LogoutButton = () => {
  const dispatch = useAppDispatch();
  const handleLogout = () => dispatch(logout());
  return (
    <div
      onClick={handleLogout}
      className={styles.logoutButtonTooltip}
      role="button"
      tabIndex={0}
    >
      Logout
    </div>
  );
};

export default function Header() {
  const [activeForm, setActiveForm] = useState<string | false>(false);

  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const location: Location = useLocation();

  useEffect(() => {
    const form = getActiveForm(location.pathname);
    setActiveForm(form);
  }, [location]);

  const handleMyProfileClick = () => {
    navigate("/dashboard/appointments");
  };

  const handleLogoClick = () => {
    if (activeForm === "register") {
      client.emit(Events.REGISTER_OPEN_EXIT_MODAL);
      return;
    }
    if (activeForm === "pcp") {
      client.emit(Events.PCP_OPEN_EXIT_MODAL);
      return;
    }
    if (activeForm === "login") {
      window.location.href = "https://cloudwellhealth.com";
      return;
    }
    if (activeForm === flowAppointment) {
      client.emit(Events.BOOKING_OPEN_EXIT_MODAL);
      return;
    }

    navigate("/dashboard/appointments");
  };

  return (
    <AppBar position="fixed" className={styles.appBar_wrapper}>
      <Container maxWidth="xl" className={styles.appBar_container}>
        <Toolbar className={styles.appBar_toolbar}>
          <span
            onClick={handleLogoClick}
            className={classNames([styles.appBar_logo], {
              [styles.hideLogoMobile]: activeForm,
            })}
            role="button"
            tabIndex={0}
          >
            <img src={appLogo} alt="CloudWell Health logo" />
          </span>
          {isAuth ? (
            <LoggedIn
              handleMyProfileClick={handleMyProfileClick}
              activeForm={activeForm}
            />
          ) : (
            <SignedOut activeForm={activeForm} />
          )}
          {!activeForm && <Drawer />}
          {activeForm && <MobileFormNav form={activeForm} />}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const SignedOut = ({ activeForm }: { activeForm: string | false }) => {
  const dispatch = useAppDispatch();
  const registrationActiveStep = useAppSelector(selectRegistrationActiveStep);

  const handleGoStepBack = () => dispatch(setRegistrationGoBack());

  const handleExit = () => client.emit(Events.REGISTER_OPEN_EXIT_MODAL);

  return (
    <menu className={styles.appBar_buttonsWrapper}>
      {activeForm === "register" && registrationActiveStep > 1 && (
        <BackStep
          onClick={handleGoStepBack}
          className={styles.backStepAction}
        />
      )}
      {activeForm === "register" && (
        <Button
          text="Exit"
          size="small"
          color="outlined_blue"
          handleClick={handleExit}
          className={styles.exitButton}
        />
      )}

      {!activeForm && (
        <Button text="Sign up" to="/register" size="small" color="blue" />
      )}
      {!activeForm && (
        <Button text="Log in" to="/login" size="small" color="outlined_blue" />
      )}
    </menu>
  );
};

const LoggedIn = ({
  handleMyProfileClick,
  activeForm,
}: {
  handleMyProfileClick: () => void;
  activeForm: string | false;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pcpActiveStep = useAppSelector(selectPcpActiveStep);
  const bookingActiveStep = useAppSelector(selectBookingActiveStep);
  const isLoadingTags = useAppSelector(selectLoadingUserTags);
  const location: Location = useLocation();

  const handlePcpGoBack = () => dispatch(setPcpGoBack());
  const handleBookingAppointmentGoBack = () =>
    dispatch(setBookingAppointmentGoBack());

  const handleExit = () => {
    if (activeForm === "pcp") {
      client.emit(Events.PCP_OPEN_EXIT_MODAL);
      return;
    }
    if (activeForm === flowAppointment) {
      client.emit(Events.BOOKING_OPEN_EXIT_MODAL);
      return;
    }

    navigate("/");
  };

  return (
    <menu className={styles.appBar_buttonsWrapper}>
      {activeForm === "pcp" && pcpActiveStep > 1 && (
        <BackStep onClick={handlePcpGoBack} className={styles.backStepAction} />
      )}
      {activeForm === flowAppointment && bookingActiveStep > 1 && (
        <BackStep
          onClick={handleBookingAppointmentGoBack}
          className={styles.backStepAction}
        />
      )}

      {activeForm && (
        <Button
          text="Exit"
          size="small"
          color="outlined_blue"
          handleClick={handleExit}
          className={styles.exitButton}
        />
      )}

      {!activeForm && (
        <ActionTooltip
          placement="bottom"
          title={<LogoutButton />}
          className={styles.actionTooltip}
        >
          <div
            className={styles.appBar_myProfile}
            role="button"
            tabIndex={0}
            onClick={handleMyProfileClick}
          >
            <p>My account</p> <ProfileIcon />
          </div>
        </ActionTooltip>
      )}
      {!activeForm && (
        <Button
          text="Book appointment"
          size="small"
          color="blue"
          isLoading={isLoadingTags}
          handleClick={() =>
            location.pathname === "/sign-up-complete"
              ? navigate(flowAppointment)
              : dispatch(fetchUserTags()).then(() => navigate(flowAppointment))
          }
        />
      )}
    </menu>
  );
};
