import { useEffect, useState } from "react";
import Button from "components/ui/Button";
import DatePicker from "components/ui/DatePicker";
import BookingService from "services/bookingService";
import { useAppDispatch, useAppSelector } from "redux/store";
import { saveFourthPcpStep, selectFourthPcpStep } from "redux/pcpFlow.slice";
import useValidate from "utils/validation";
import { VALIDATE_TIME_SLOT } from "utils/validation/constants";
import client, { Events } from "services/EventEmitter";
import { defaultFormatDate } from "utils/transform/formatDate";
import {
  saveFourthBookingAppointmentStep,
  selectFourthBookingAppointmentStep,
} from "redux/bookAppointmentFlow.slice";
import useAppointmentData from "hooks/useAppointmentData";
import { AxiosResponse } from "axios";
import TimeSlotPicker from "../TimeSlotPicker";
import ActionsContainer from "../../forms/FormContainer/ActionsContainer";
import ValidationError from "../ValidationError";

interface Props {
  nextStep: (stepId: number) => void;
  bookingAppointment?: boolean;
}

interface ITimeSlotsData {
  availableTimeslots: string[];
}

function TimeAndDate({ nextStep, bookingAppointment = false }: Props) {
  const dispatch = useAppDispatch();
  const isPcp = !bookingAppointment;
  const fourthStep = useAppSelector(
    isPcp ? selectFourthPcpStep : selectFourthBookingAppointmentStep
  );

  const { appointmentTypeName } = useAppointmentData(bookingAppointment);

  const [selectedDay, setSelectedDay] = useState<Date>(
    fourthStep.appointmentDate
  );

  const [slots, setSlots] = useState<string[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<string>(fourthStep.timeSlot);
  const [isLoading, setIsLoading] = useState(false);

  const [isTimeSlotValid, timeSlotErrors] = useValidate(
    selectedSlot,
    VALIDATE_TIME_SLOT
  );

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    setHaveErrors(!isTimeSlotValid || !selectedDay);
  }, [isTimeSlotValid, selectedDay]);

  useEffect(() => {
    setIsLoading(true);
    const date = defaultFormatDate(selectedDay);

    BookingService.findAppointmentByDate({
      date,
      timezone: "Pacific/Honolulu",
      appointmentType: appointmentTypeName,
    })
      .then((res: AxiosResponse<ITimeSlotsData>) => {
        const slots = res.data.availableTimeslots;
        const transformedSlots = slots.map(
          (slot: string) => `${slot}@${defaultFormatDate(selectedDay)}`
        );
        setSlots(transformedSlots);
      })
      .catch(() => null)
      .finally(() => setIsLoading(false));
  }, [selectedDay]);

  const handleSlotSelect = (slot: string) => setSelectedSlot(slot);

  const handleContinue = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    if (isPcp) {
      dispatch(
        saveFourthPcpStep({
          appointmentDate: selectedDay,
          timeSlot: selectedSlot,
        })
      );
    } else {
      dispatch(
        saveFourthBookingAppointmentStep({
          appointmentDate: selectedDay,
          timeSlot: selectedSlot,
        })
      );
    }
    nextStep(5);
  };

  return (
    <>
      <h2 className="FormComponent-title">Time and date</h2>
      <p className="FormComponent-description">
        When do you want to speak to a doctor? Please select a date, and a time
        slot.
      </p>
      <DatePicker
        label="Select a date"
        day={selectedDay}
        onChange={setSelectedDay}
      />
      <TimeSlotPicker
        label="Select a time slot"
        slots={slots}
        selectedSlotId={selectedSlot}
        handleSlotSelect={handleSlotSelect}
        isLoading={isLoading}
      />
      <ValidationError show={showErrors} error={timeSlotErrors[0]} />
      <ActionsContainer>
        <Button
          text="Continue"
          descriptionText="Select payment type"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
        />
      </ActionsContainer>
    </>
  );
}

export default TimeAndDate;
