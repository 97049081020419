import { useEffect, useState } from "react";
import Button from "components/ui/Button";
import InputField from "components/ui/InputField";
import DobPicker from "components/ui/DobPicker";
import Dropdown from "components/ui/Dropdown";
import {
  Gender,
  saveFirstStep,
  selectFirstStep,
  setAdSource,
} from "redux/registration.slice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { useValidate } from "utils/validation";
import {
  VALIDATE_DATE_OF_BIRTH,
  VALIDATE_FIRST_NAME,
  VALIDATE_IS_GENDER_SELECTED,
  VALIDATE_LAST_NAME,
} from "utils/validation/constants";
import client, { Events } from "services/EventEmitter";
import Modal from "components/shared/Modal";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";

interface Props {
  nextStep: (stepId: number) => void;
}

export default function FirstStep({ nextStep }: Props) {
  const dispatch = useAppDispatch();
  const firstStep = useAppSelector(selectFirstStep);

  const [firstName, setFirstName] = useState<string>(firstStep.firstName);
  const [lastName, setLastName] = useState<string>(firstStep.lastName);
  const [dateOfBirth, setDateOfBirth] = useState<string>(firstStep.dateOfBirth);
  const [gender, setGender] = useState<Gender | null>(firstStep.gender);
  const [childrenInfoModal, setChildrenInfoModal] = useState<boolean>(false);

  const [isFirstNameValid, firstNameErrors] = useValidate(
    firstName,
    VALIDATE_FIRST_NAME
  );

  const [isLastNameValid, lastNameErrors] = useValidate(
    lastName,
    VALIDATE_LAST_NAME
  );

  const [isDobValid, dobErrors] = useValidate(
    dateOfBirth,
    VALIDATE_DATE_OF_BIRTH
  );

  const [isGenderSelected, genderErrors] = useValidate(
    gender,
    VALIDATE_IS_GENDER_SELECTED
  );

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    const getCookieValue = (name: string) =>
      document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || "";

    try {
      const {
        ad_source, // eslint-disable-line
        gclid,
        utm_source, // eslint-disable-line
        utm_medium, // eslint-disable-line
        utm_campaign, // eslint-disable-line
        utm_content, // eslint-disable-line
      } = JSON.parse(getCookieValue("AdUTMCookie"));

      console.log({
        ad_source,
        gclid,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
      });

      if (gclid) {
        dispatch(
          setAdSource({
            ad_source,
            gclid,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
          })
        );
      }
    } catch (ex) {
      // console.log(`error${ex}`);
    }
  }, []);

  useEffect(() => {
    setHaveErrors(
      !isFirstNameValid || !isLastNameValid || !isDobValid || !isGenderSelected
    );
  }, [isFirstNameValid, isLastNameValid, isDobValid, isGenderSelected]);

  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleChangeDateOfBirth = (dob: string) => {
    setDateOfBirth(dob);
  };

  const handleChangeGender = (selected: Gender) => {
    setGender(selected);
  };

  const genders = [
    { value: Gender.Male, display: Gender.Male },
    { value: Gender.Female, display: Gender.Female },
  ];

  const handleOpenChildrenInfoModal = () => {
    setChildrenInfoModal(true);
  };

  const closeChildrenInfoModal = () => {
    setChildrenInfoModal(false);
  };

  const handleContinue = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    dispatch(
      saveFirstStep({
        firstName,
        lastName,
        dateOfBirth,
        gender,
      })
    );
    nextStep(2);
  };

  return (
    <>
      <h2 className="FormComponent-title">Register your Cloudwell account</h2>
      <p className="FormComponent-description">
        Firstly, please enter some personal details so that we can generate your
        profile.
        <p className={styles.childInfo}>
          Registering for your child?{" "}
          <span
            onClick={handleOpenChildrenInfoModal}
            role="button"
            tabIndex={0}
            aria-hidden="true"
          >
            {" "}
            Find out how to register children here.
          </span>
        </p>
      </p>
      <InputField
        value={firstName}
        onChange={handleChangeFirstName}
        error={firstNameErrors[0]}
        showError={showErrors}
        label="First name"
        id="firstName"
      />

      <InputField
        value={lastName}
        onChange={handleChangeLastName}
        error={lastNameErrors[0]}
        showError={showErrors}
        label="Last name"
        id="lastName"
      />
      <DobPicker
        initialValue={dateOfBirth}
        onChange={handleChangeDateOfBirth}
        label="Date of birth"
        error={dobErrors[0]}
        showError={showErrors}
      />
      <Dropdown
        initialValue={firstStep.gender}
        options={genders}
        onChange={handleChangeGender}
        label="Sex at birth"
        error={genderErrors[0]}
        showError={showErrors}
      />
      <ActionsContainer>
        <Button
          text="Continue"
          descriptionText="Select login details"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
        />
      </ActionsContainer>
      {/* Modal */}
      <Modal
        isOpened={childrenInfoModal}
        handleClose={closeChildrenInfoModal}
        title="How to register children"
        classes={styles.modalStyle}
        buttons={
          <Button
            text="Ok, got it"
            color="blue"
            size="small"
            handleClick={closeChildrenInfoModal}
          />
        }
      >
        <>
          <p className={styles.modalDescription}>
            Patients aged under 18 must be registered on their own account. They
            can’t be added to an parent or guardian’s account.
          </p>
          <p className={styles.modalDescription}>
            In order to register, a <span>unique email address</span> must be
            used. That email address could be their own, or one managed by a
            parent or guardian.
          </p>
          <p className={styles.modalDescription}>
            Certain limitations are places on children’s accounts for
            safeguarding reasons - they can only book video appointments with an
            adult present, and are unable to register with us as a primary care
            provider.
          </p>
        </>
      </Modal>
    </>
  );
}
