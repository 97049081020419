import { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";

interface Props {
  redirect?: string;
  replace?: boolean;
}

type IProtectedRoute = ReactElement | null;

function ProtectedRoute({
  redirect = "/login",
  replace = true,
}: Props): IProtectedRoute {
  const { isAuth } = useAuth();

  if (isAuth) {
    return <Outlet />;
  }

  return <Navigate to={redirect} replace={replace} />;
}

export default ProtectedRoute;
