import { useState } from "react";
import { useAppSelector } from "redux/store";
import { selectUserElationId } from "redux/user.slice";
import ExpandableForm from "components/shared/ExpandableForm/ExpandableForm";
import InsuranceDetails from "./InsuranceDetails";
import LoginDetails from "./LoginDetails";
import ContactDetails from "./ContactDetails";
import PersonalDetails from "./PersonalDetails";
import styles from "./styles.module.scss";
import ChangeEmail from "./ChangeEmail";

function MyProfile() {
  const [formExpanded, setFormExpanded] = useState<number>(0);
  const userId = useAppSelector(selectUserElationId);

  const toggleForm = (id: number) => {
    setFormExpanded(id);
  };

  return (
    <div className={styles.myProfilePaddingWrapper}>
      <h2 className="FormComponent-title">My profile</h2>
      <p className="FormComponent-description">Patient ID - {userId}</p>
      <ExpandableForm
        id={1}
        title="Personal details"
        description="Name, date of birth and sex at birth"
        form={<PersonalDetails handleClose={() => setFormExpanded(0)} />}
        isExpanded={formExpanded === 1}
        readOnly={true}
        handleExpand={toggleForm}
      />
      <ExpandableForm
        id={2}
        title="Email"
        description="The email address you use to login"
        form={<ChangeEmail handleClose={() => setFormExpanded(0)} />}
        isExpanded={formExpanded === 2}
        handleExpand={toggleForm}
      />
      <ExpandableForm
        id={3}
        title="Password"
        description="Change your password"
        form={<LoginDetails handleClose={() => setFormExpanded(0)} />}
        isExpanded={formExpanded === 3}
        handleExpand={toggleForm}
      />
      <ExpandableForm
        id={4}
        title="Contact details"
        description="Phone number and address"
        form={<ContactDetails handleClose={() => setFormExpanded(0)} />}
        isExpanded={formExpanded === 4}
        handleExpand={toggleForm}
      />
      <ExpandableForm
        id={5}
        title="Insurance details"
        description="Provider and membership details"
        form={<InsuranceDetails handleClose={() => setFormExpanded(0)} />}
        isExpanded={formExpanded === 5}
        handleExpand={toggleForm}
      />
    </div>
  );
}

export default MyProfile;
