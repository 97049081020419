import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  clearPcpState,
  selectPcpGoBack,
  setPcpActiveStep,
} from "redux/pcpFlow.slice";
import client, { Events } from "services/EventEmitter";
import TimeAndDate from "components/shared/TimeAndDate";
import Modal from "components/shared/Modal";
import Stepper from "components/ui/Stepper";
import Button from "components/ui/Button";
import FormContainer from "components/forms/FormContainer";
import PCPInitial from "components/forms/PCPInitial";
import AppointmentType from "components/forms/AppointmentType";
import Payment from "components/forms/Payment";
import HealthConcern from "components/forms/HealthConcern";
import scrollToTop from "utils/scrollToTop";
import { selectIsPcpNotAppropriate } from "redux/user.slice";

function Pcp() {
  const dispatch = useAppDispatch();
  const triggerGoBack = useAppSelector(selectPcpGoBack);
  const isPcpNotAppropriate = useAppSelector(selectIsPcpNotAppropriate);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<number>(1);
  const [isExitModalOpened, setIsExitModalOpened] = useState<boolean>(false);

  const handleChangeActiveStep = (nextStepid: number) => {
    dispatch(setPcpActiveStep(nextStepid));
    setActiveStep(nextStepid);
  };

  useEffect(() => {
    dispatch(setPcpActiveStep(1));
  }, []);

  useEffect(() => {
    if (isPcpNotAppropriate) navigate("/");
  }, [isPcpNotAppropriate]);

  useEffect(() => {
    if (activeStep > 1) {
      handleChangeActiveStep(activeStep - 1);
    }
  }, [triggerGoBack]);

  useEffect(() => {
    client.on(Events.PCP_OPEN_EXIT_MODAL, openModal);
    return () => {
      client.remove(Events.PCP_OPEN_EXIT_MODAL, openModal);
      client.emit(Events.ENABLE_SCROLL, 0);
    };
  }, []);

  const openModal = () => setIsExitModalOpened(true);

  const closeExitModal = () => {
    setIsExitModalOpened(false);
  };

  const exitPcpForm = () => {
    dispatch(clearPcpState());
    navigate("/dashboard/appointments");
  };

  const steps = [
    {
      stepId: 1,
      form: <PCPInitial nextStep={handleChangeActiveStep} />,
      isActive: activeStep === 1,
    },
    {
      stepId: 2,
      form: <AppointmentType nextStep={handleChangeActiveStep} />,
      isActive: activeStep === 2,
    },
    {
      stepId: 3,
      form: <HealthConcern nextStep={handleChangeActiveStep} />,
      isActive: activeStep === 3,
    },
    {
      stepId: 4,
      form: <TimeAndDate nextStep={handleChangeActiveStep} />,
      isActive: activeStep === 4,
    },
    {
      stepId: 5,
      form: <Payment nextStep={handleChangeActiveStep} />,
      isActive: activeStep === 5,
    },
  ];

  const ActiveStep = steps[activeStep - 1].form;

  useEffect(() => {
    scrollToTop();
  }, [activeStep]);

  return (
    <FormContainer>
      <Stepper steps={steps} activeStep={activeStep} form="pcp" />
      {ActiveStep}
      <Modal
        isOpened={isExitModalOpened}
        handleClose={closeExitModal}
        title="Exit booking?"
        buttons={
          <>
            <Button
              text="Cancel"
              color="outlined_blue"
              size="small"
              handleClick={closeExitModal}
            />
            <Button
              text="Exit booking"
              color="red"
              size="small"
              handleClick={exitPcpForm}
            />
          </>
        }
      >
        <>
          Are you sure you want to exit this process? The information you have
          entered on this step will not be saved.
        </>
      </Modal>
    </FormContainer>
  );
}

export default Pcp;
