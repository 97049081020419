import {
  hasNumber,
  hasSymbol,
  hasUpperCase,
  isFutureDate,
  isOlderThan1900,
  isValidDate,
  isValidEmail,
  isZipCodeValid,
  longerThanEightChars,
  onlyNumbers,
} from "./helpers";
import * as messages from "./messages";

export type Validation = [isValid: boolean, errors: string[]];

const validateFirstName = (firstName: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (firstName === "") {
    isValid = false;
    errors.push(messages.ERROR_FIRST_NAME_EMPTY);
  }

  return [isValid, errors];
};

const validateLastName = (lastName: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (lastName === "") {
    isValid = false;
    errors.push(messages.ERROR_LAST_NAME_EMPTY);
  }

  return [isValid, errors];
};

const validateDateOfBirth = (dob: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!isValidDate(dob) || isFutureDate(dob) || isOlderThan1900(dob)) {
    isValid = false;
    errors.push(messages.ERROR_DATE_OF_BIRTH_NOT_VALID_DATE);
  }

  return [isValid, errors];
};

const validateGenderSelected = (gender: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!gender) {
    isValid = false;
    errors.push(messages.ERROR_GENDER_NOT_SELECTED);
  }

  return [isValid, errors];
};

const validateEmail = (email: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!email) {
    isValid = false;
    errors.push(messages.ERROR_EMAIL_EMPTY);
  } else if (!isValidEmail(email)) {
    isValid = false;
    errors.push(messages.ERROR_EMAIL_NOT_VALID);
  }

  return [isValid, errors];
};

const validatePassword = (password: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!hasNumber(password)) {
    isValid = false;
    errors.push(messages.ERROR_PASSWORD_ONE_NUMBER);
  }

  if (!hasUpperCase(password)) {
    isValid = false;
    errors.push(messages.ERROR_PASSWORD_ONE_UPPERCASE_LETTER);
  }

  if (!hasSymbol(password)) {
    isValid = false;
    errors.push(messages.ERROR_PASSWORD_ONE_SYMBOL);
  }

  if (!longerThanEightChars(password)) {
    isValid = false;
    errors.push(messages.ERROR_PASSWORD_LENGTH);
  }

  return [isValid, errors];
};

const validatePhoneNumber = (phoneNumber: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!phoneNumber) {
    isValid = false;
    errors.push(messages.ERROR_PHONE_EMPTY);
  }

  if (!onlyNumbers(phoneNumber) || phoneNumber.length !== 10) {
    isValid = false;
    errors.push(messages.ERROR_PHONE_ONLY_NUMBERS);
  }
  return [isValid, errors];
};

const validateSecondaryPhoneNumber = (phoneNumber: string) => {
  const isValid = true;
  const errors: string[] = [];

  if (phoneNumber === "") return [isValid, errors] as Validation;
  return validatePhoneNumber(phoneNumber);
};

const validateAddress = (value: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (value === "") {
    isValid = false;
    errors.push(messages.ERROR_ADDRESS_EMPTY);
  }

  return [isValid, errors];
};

const validateCitySelected = (city: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!city) {
    isValid = false;
    errors.push(messages.ERROR_CITY_NOT_SELECTED);
  }

  return [isValid, errors];
};

const validateStateSelected = (state: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!state) {
    isValid = false;
    errors.push(messages.ERROR_STATE_NOT_SELECTED);
  }

  return [isValid, errors];
};

const validateZip = (zip: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!zip) {
    isValid = false;
    errors.push(messages.ERROR_ZIP_EMPTY);
  }

  if (!isZipCodeValid(zip)) {
    isValid = false;
    errors.push(messages.ERROR_ZIP_NOT_VALID_FORMAT);
  }

  return [isValid, errors];
};

const validatePcpName = (pcpName: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!pcpName) {
    isValid = false;
    errors.push(messages.ERROR_PCP_NAME_EMPTY);
  }

  return [isValid, errors];
};

const validatePcpAddress = (pcpAddress: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!pcpAddress) {
    isValid = false;
    errors.push(messages.ERROR_PCP_ADDRESS_EMPTY);
  }

  return [isValid, errors];
};

const validateAppointmentPhoneNumber = (phone: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!phone) {
    isValid = false;
    errors.push(messages.ERROR_APPOINTMENT_PHONE_EMPTY);
  }

  return [isValid, errors];
};

const validateHealthConcernDescription = (description: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!description) {
    isValid = false;
    errors.push(messages.ERROR_HEALTH_CONCERN_DESCRIPTION_EMPTY);
  }

  if (description.length > 2000) {
    isValid = false;
    errors.push(messages.ERROR_HEALTH_CONCERN_DESCRIPTION_OVER_2000);
  }

  return [isValid, errors];
};

const validateTimeSlot = (timeSlot: any): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!timeSlot) {
    isValid = false;
    errors.push(messages.TIME_SLOT_NOT_SELECTED);
  }

  return [isValid, errors];
};

const validateInsuranceProvider = (insuranceProvider: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!insuranceProvider) {
    isValid = false;
    errors.push(messages.INSURANCE_PROVIDER_NOT_SELECTED);
  }

  return [isValid, errors];
};

const validateInsuranceNumber = (insuranceNumber: any): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!insuranceNumber) {
    isValid = false;
    errors.push(messages.INSURANCE_NUMBER_NOT_SELECTED);
  }

  return [isValid, errors];
};

const validateInsuranceRegisteredAddress = (address: string): Validation => {
  let isValid = true;
  const errors: string[] = [];

  if (!address) {
    isValid = false;
    errors.push(messages.INSURANCE_REGISTERED_ADDRESS);
  }

  return [isValid, errors];
};

const isValid = {
  validateFirstName,
  validateLastName,
  validateDateOfBirth,
  validateGenderSelected,
  validateEmail,
  validatePassword,
  validatePhoneNumber,
  validateSecondaryPhoneNumber,
  validateAddress,
  validateCitySelected,
  validateStateSelected,
  validateZip,
  validatePcpName,
  validatePcpAddress,
  validateAppointmentPhoneNumber,
  validateHealthConcernDescription,
  validateTimeSlot,
  validateInsuranceProvider,
  validateInsuranceNumber,
  validateInsuranceRegisteredAddress,
};

export const useValidate = (value: any, validator: keyof typeof isValid) =>
  isValid[validator](value);

export default useValidate;
