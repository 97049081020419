import Modal from "components/shared/Modal";
import Button from "components/ui/Button";
import { selectFourthBookingAppointmentStep } from "redux/bookAppointmentFlow.slice";
import { selectFourthPcpStep } from "redux/pcpFlow.slice";
import { useAppSelector } from "redux/store";

import ConfirmModal from "./ConfirmModal";

interface IPaymentModal {
  showModal: boolean;
  handleCancel: () => void;
  slotTaken: boolean;
  handleCancelSecondary: () => void;
  handleConfirm: () => void;
  handleConfirmSecondary: () => void;
  bookingAppointment: boolean | undefined;
  tabActive: "useInsurance" | "selfPay";
  isLoading: boolean;
  bookingError?: boolean;
  appointmentType: "phone" | "video" | null;
}

const PaymentModal = ({
  showModal,
  handleCancel,
  handleCancelSecondary,
  slotTaken,
  handleConfirm,
  handleConfirmSecondary,
  bookingAppointment,
  tabActive,
  isLoading,
  bookingError = false,
  appointmentType,
}: IPaymentModal) => {
  const { timeSlot } = useAppSelector(
    bookingAppointment
      ? selectFourthBookingAppointmentStep
      : selectFourthPcpStep
  );

  return bookingError ? (
    <Modal
      isOpened={showModal}
      handleClose={handleCancel}
      title="Something went wrong"
      warningColor
      buttons={
        <Button
          text="Ok, got it"
          color="blue"
          size="small"
          handleClick={handleCancel}
          isLoading={isLoading}
          withoutLoadingSpinner
        />
      }
    >
      <>
        <span>There was an error processing your request.</span>
        <br />
        <span> Please try again later</span>
      </>
    </Modal>
  ) : (
    <Modal
      isOpened={showModal}
      handleClose={handleCancel}
      title={!slotTaken ? "Confirm booking?" : "Slot unavailable"}
      buttons={
        <>
          <Button
            text={!slotTaken ? "Go back" : "Exit booking"}
            color="outlined_blue"
            size="small"
            handleClick={!slotTaken ? handleCancel : handleCancelSecondary}
            isLoading={isLoading}
            withoutLoadingSpinner
          />

          <Button
            text={!slotTaken ? "Confirm booking" : "Select different time slot"}
            color="blue"
            size="small"
            handleClick={!slotTaken ? handleConfirm : handleConfirmSecondary}
            isLoading={isLoading}
          />
        </>
      }
    >
      <ConfirmModal
        timeSlot={timeSlot}
        paymentType={tabActive}
        slotTaken={slotTaken}
        appointmentType={appointmentType}
      />
    </Modal>
  );
};

export default PaymentModal;
