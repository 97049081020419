import { useState, useLayoutEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as PrimaryCare } from "assets/images/primaryCare.svg";
import { ReactComponent as IconWellnessTelephysical } from "assets/images/iconWellnessTelephysical.svg";
import { ReactComponent as EmotionalWellness } from "assets/images/emotionalWellness.svg";
import { ReactComponent as IconChild } from "assets/images/iconChild.svg";
import { ReactComponent as InfoIcon } from "assets/images/infoIcon.svg";
import { ReactComponent as GreenCheckmark } from "assets/images/greenCheckmark.svg";
import Modal from "components/shared/Modal";
import { useAppDispatch, useAppSelector } from "redux/store";
import { selectChildTag, selectIsPcpConfirmed } from "redux/user.slice";
import { logout } from "redux/auth.slice";
import Button from "components/ui/Button";
import classNames from "classnames";
import {
  clearBookingAppointmentState,
  saveFirstBookingAppointmentStep,
} from "redux/bookAppointmentFlow.slice";
import client, { Events } from "services/EventEmitter";
import { removeItem } from "utils/localStorageUtils";
import ValidationError from "components/shared/ValidationError";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";

interface Props {
  nextStep: (stepId: number) => void;
}

export interface IModalData {
  modalTitle: string;
  modalDescription: string | JSX.Element;
  buttons?: {
    buttonOneText: string;
    buttonOneHandler: any;
    buttonTwoText: string;
    buttonTwoHandler: any;
  };
  speakToUs?: boolean;
  warningColor?: boolean;
}

interface IHealthServices {
  id: number;
  name: AppointmentTypeNameEnum;
  icon: JSX.Element;
  description: string;
  forAdults: boolean;
  modalTitle: AppointmentTypeNameEnum;
  modalDescription: string;
}

export enum AppointmentTypeNameEnum {
  MEDICAL_CARE = "Medical Care",
  EMOTIONAL_WELLNESS = "Emotional Wellness",
  PEDIATRICS = "Pediatrics",
  TELEPHYSICAL = "Telephysical",
}

export const bookingServices: IHealthServices[] = [
  {
    id: 1,
    name: AppointmentTypeNameEnum.MEDICAL_CARE,
    icon: <PrimaryCare />,
    description:
      "Choose this option if you have a health concern you want to discuss with a clinician or need a prescription refill.",
    forAdults: true,
    modalTitle: AppointmentTypeNameEnum.MEDICAL_CARE,
    modalDescription:
      "Our experienced Hawaii board-certified doctors can help with medical concerns ranging from coughs, colds and viruses to prescription refills, mens/womens health issues, minor infections and more.",
  },
  {
    id: 2,
    name: AppointmentTypeNameEnum.TELEPHYSICAL,
    icon: <IconWellnessTelephysical />,
    description:
      "Choose this option if you want to book an online annual wellness check.",
    forAdults: true,
    modalTitle: AppointmentTypeNameEnum.TELEPHYSICAL,
    modalDescription:
      "Our practitioners will discuss your lifestyle, any outstanding health concerns and check in on your emotional wellbeing. We can also check your vitals and arrange lab tests.",
  },
  {
    id: 3,
    name: AppointmentTypeNameEnum.EMOTIONAL_WELLNESS,
    icon: <EmotionalWellness />,
    description:
      "Choose this option to speak with a mental health expert about stress, depression, anxiety or low mood.",
    forAdults: true,
    modalTitle: AppointmentTypeNameEnum.EMOTIONAL_WELLNESS,
    modalDescription:
      "Our mental health experts provide personalised treatment plans for anxiety, stress depression, bereavement and low mood. Access regular, confidential appointments from the comfort of your own home.",
  },
  {
    id: 4,
    name: AppointmentTypeNameEnum.PEDIATRICS,
    icon: <IconChild />,
    description:
      "Choose this option if you’re booking an appointment for an infant, child or adolescent.",
    forAdults: false,
    modalTitle: AppointmentTypeNameEnum.PEDIATRICS,
    modalDescription:
      "Our experienced pediatricians provide family-centred care for infants, children and adolescents. We consult with patients of all ages and diagnose, treat and follow up to ensure your child’s wellbeing.",
  },
];

export default function ChooseYourService({ nextStep }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const childTag = useAppSelector(selectChildTag);
  const isPcpConfirmed = useAppSelector(selectIsPcpConfirmed);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showErrorState, setShowErrorState] = useState<boolean>(false);

  const isUserAdult = !childTag; //  this should be taken from redux later on
  const [chosenService, setChosenService] = useState<null | IHealthServices>(
    null
  );
  const [modalData, setModalData] = useState<IModalData | null>(null);

  const setServiceHandler = (id: number): void => {
    setShowErrorState(false);

    const service = getService(id);
    if (!service || service === chosenService) return;
    setChosenService(service);
  };

  const modalDataHandler = (id: number): void => {
    const service = getService(id);
    if (!service) return;
    setModalData({
      modalTitle: formatServiceTitle(service.modalTitle, isPcpConfirmed),
      modalDescription: service.modalDescription,
    });
  };

  const handleCloseModal = () => setModalData(null);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClearPaymentError = () => {
    searchParams.delete("paymentError");
    searchParams.delete("unknownError");
    setSearchParams(searchParams);
    removeItem("stripe_session_id");
  };

  useLayoutEffect(() => {
    if (searchParams.get("unknownError")) {
      setModalData({
        modalTitle: "An unknown error occured",
        modalDescription:
          "There was a problem with payment or booking appointment",
        warningColor: true,
      });
      handleClearPaymentError();
      return;
    }
    if (searchParams.get("paymentError")) {
      setModalData({
        modalTitle: "Stripe payment was cancelled",
        modalDescription: "Payment was cancelled",
      });
    }
    handleClearPaymentError();
  }, [searchParams]);

  const exitBookingAppointment = () => {
    dispatch(clearBookingAppointmentState());
    navigate("/dashboard/appointments");
  };

  const handleContinue = (modalCheck: boolean = false) => {
    if (!chosenService) {
      setShowErrorState(true);
      client.emit(Events.SCROLL_TO_TOP);
      return;
    }

    setShowErrorState(false);

    if (chosenService.id === 3 && !modalCheck) {
      handleEWModalFirstStep();
      return;
    }

    if (chosenService.id === 4 && !modalCheck) {
      handleChildContinue();
      return;
    }
    dispatch(
      saveFirstBookingAppointmentStep({
        id: chosenService.id,
        name: chosenService.name,
        forAdults: chosenService.forAdults,
      })
    );

    nextStep(2);
  };

  const handleEWModalFirstStep = () => {
    setModalData({
      modalTitle: "Before we get started",
      modalDescription:
        "Are you experiencing thoughts of harming yourself or harming another person?",
      buttons: {
        buttonOneText: "Yes - I am",
        buttonOneHandler: handleEWModalSecondStep,
        buttonTwoText: "No I am not",
        buttonTwoHandler: () => {
          handleContinue(true);
          client.emit(Events.ENABLE_SCROLL, 0);
        },
      },
    });
  };

  const handleEWModalSecondStep = () => {
    setModalData({
      modalTitle: "A message for your safety",
      modalDescription: <SafetyModal />,
      buttons: {
        buttonOneText: "Exit",
        buttonOneHandler: exitBookingAppointment,
        buttonTwoText: "",
        buttonTwoHandler: () => {},
      },
      warningColor: true,
    });
  };

  const handleChildContinue = () => {
    setModalData({
      modalTitle: "Appointments for children",
      modalDescription:
        "A parent, or individual with legal parental responsibility, will need to be present with the child on the call",
      buttons: {
        buttonOneText: "Go back",
        buttonOneHandler: exitBookingAppointment,
        buttonTwoText: "Continue",
        buttonTwoHandler: () => {
          handleContinue(true);
          client.emit(Events.ENABLE_SCROLL, 0);
        },
      },
    });
  };

  const handleChildRegisterHelp = () => {
    setModalData({
      modalTitle: "How to register a child",
      modalDescription:
        "In order to book an appointment for a child, they need to have a dedicated account - set up either by themselves or by a guardian. It must be associated with a unique email address.",
      buttons: {
        buttonOneText: "Close",
        buttonOneHandler: handleCloseModal,
        buttonTwoText: "Log out and register a child",
        buttonTwoHandler: handleLogout,
      },
    });
  };

  const handleSpeakToUs = () => {
    setModalData({
      modalTitle: "Contact us",
      modalDescription: <SpeakToUs isChild={childTag} />,
      speakToUs: true,
    });
  };

  return (
    <>
      <h2 className="FormComponent-title">Choose your service</h2>
      <div className={styles.uploads_wrapper}>
        {bookingServices.map(({ id, name, icon, description, forAdults }) => (
          <ServiceOption
            key={id}
            serviceId={id}
            name={name}
            description={description}
            icon={icon}
            enabledService={forAdults === isUserAdult}
            forAdults={forAdults}
            serviceHandler={setServiceHandler}
            chosenServiceId={chosenService}
            setModalData={modalDataHandler}
            isPcpConfirmed={isPcpConfirmed}
            errorState={showErrorState}
          />
        ))}
        {showErrorState && (
          <ValidationError
            show={showErrorState}
            error="Plese select an option"
            className={styles.errorMessage}
          />
        )}
      </div>

      <div>
        {isUserAdult && (
          <p className={styles.uploads_talkToUs}>
            Need to book for a child? &nbsp;
            <button
              className={classNames([styles.buttonWrapper, styles.buttonLink])}
              onClick={handleChildRegisterHelp}
            >
              Find out how to register children here.
            </button>
          </p>
        )}
        <p className={styles.uploads_talkToUs}>
          Can’t find what you’re looking for? Or unsure about what you need?
          &nbsp;
          <button
            className={classNames([styles.buttonWrapper, styles.buttonLink])}
            onClick={handleSpeakToUs}
          >
            Speak to us.
          </button>
        </p>
      </div>
      <Modal
        isOpened={Boolean(modalData?.modalTitle)}
        handleClose={handleCloseModal}
        title={String(modalData?.modalTitle)}
        warningColor={modalData?.warningColor}
        buttons={
          <>
            {modalData?.buttons ? (
              <div className={styles.modalButtonSpacing}>
                <Button
                  text={modalData.buttons.buttonOneText}
                  color={
                    modalData?.warningColor ? "red_outline" : "outlined_blue"
                  }
                  size="small"
                  handleClick={modalData.buttons.buttonOneHandler}
                />
                {modalData.buttons.buttonTwoText && (
                  <Button
                    text={modalData.buttons.buttonTwoText}
                    color="blue"
                    size="small"
                    handleClick={modalData.buttons.buttonTwoHandler}
                  />
                )}
              </div>
            ) : (
              <Button
                text={modalData?.speakToUs ? "Continue" : "Ok, got it"}
                color="blue"
                size="small"
                handleClick={handleCloseModal}
              />
            )}
          </>
        }
      >
        <>{modalData?.modalDescription}</>
      </Modal>

      <ActionsContainer>
        <Button
          text="Continue (Choose appointment type)"
          size="large"
          color="blue"
          handleClick={handleContinue}
        />
      </ActionsContainer>
    </>
  );
}

interface ServiceOptionProps {
  icon?: JSX.Element;
  name: AppointmentTypeNameEnum;
  description: string;
  enabledService: boolean;
  forAdults: boolean;
  serviceId: number;
  serviceHandler: (id: number) => void;
  chosenServiceId: IHealthServices | null;
  setModalData: (id: number) => void;
  isPcpConfirmed: boolean;
  errorState?: boolean;
}

function ServiceOption({
  name,
  icon,
  description,
  enabledService,
  forAdults,
  serviceId,
  serviceHandler,
  chosenServiceId,
  setModalData,
  isPcpConfirmed,
  errorState,
}: ServiceOptionProps) {
  const serviceChosen = serviceId === chosenServiceId?.id;

  const modifiedName = formatServiceTitle(name, isPcpConfirmed);

  return (
    <div className={styles.packages_optionWrapper}>
      <button
        className={styles.buttonWrapper}
        onClick={() => {
          if (!enabledService) return;
          serviceHandler(serviceId);
        }}
      >
        <div
          className={classNames([styles.packages_optionDetails], {
            [styles.disabled]: !enabledService,
            [styles.optionChosen]: serviceChosen,
            [styles.errorState]: errorState && enabledService,
          })}
        >
          {serviceChosen ? (
            <div>
              <GreenCheckmark />
            </div>
          ) : (
            <div
              className={
                enabledService ? styles.enabledIcon : styles.disabledIcon
              }
            >
              {icon}
            </div>
          )}
          <div
            className={classNames([styles.packages_package], {
              [styles.disabled]: !enabledService,
              [styles.enabled]: serviceChosen,
            })}
          >
            <p>{modifiedName}</p>
            <div>{description}</div>
            {!enabledService && (
              <div className={styles.ageNotifier}>
                {forAdults
                  ? "Available to adults only - please register separately"
                  : "Available to children only - please register child separately"}
              </div>
            )}
          </div>
        </div>
      </button>
      <div className={styles.packages_infoIcon}>
        <button
          className={classNames([styles.buttonWrapper, styles.cursorPointer])}
          onClick={() => setModalData(serviceId)}
        >
          <InfoIcon className={styles.packages_info} />
        </button>
      </div>
    </div>
  );
}

export const SpeakToUs = ({
  customText,
  customContactUs,
  isChild,
}: {
  customText?: string;
  customContactUs?: boolean;
  isChild?: boolean;
}) => (
  <div className={styles.speakToUs}>
    <span>
      {customText ?? isChild
        ? "Questions about your account or Cloudwell’s services?"
        : "Questions about your account or Cloudwell’s services?"}
    </span>
    {!customContactUs && (
      <>
        <br />
        <span>Please contact us.</span>
      </>
    )}
    <hr className={styles.hr} />
    <span>Phone: (808) 207-9355</span>
    <br />
    <span>Email: info@cloudwellhealth.com</span>
    <hr className={styles.hr} />
    <div className={classNames(styles.uploads_talkToUs, styles.speakToUs)}>
      <button
        className={classNames([
          styles.buttonWrapper,
          styles.buttonLink,
          styles.speakToUs,
          styles.speakToUsLink,
        ])}
        onClick={() =>
          window.open("https://cloudwellhealth.com/contact/", "_blank")
        }
      >
        More details here.
      </button>
    </div>
  </div>
);

const SafetyModal = () => {
  const contact = "<contact details needed>";
  return (
    <div>
      <span>
        If you are at risk of harming yourself or another person please contact
        local emergency services by dialling 911 or go to your nearest emergency
        room.
      </span>
      <hr className={styles.hr} />
      <span>
        For crisis support, you can dial 988 for the Suicide & Crisis Lifeline
        for immediate help.
      </span>
    </div>
  );
};

// utils
const getService = (id: number): IHealthServices | undefined =>
  bookingServices.find((service) => service.id === id);

const formatServiceTitle = (
  modalTitle: AppointmentTypeNameEnum,
  isPcpConfirmed: boolean
) => {
  if (isPcpConfirmed && modalTitle === AppointmentTypeNameEnum.TELEPHYSICAL) {
    return "Annual Wellness Exam";
  }
  if (modalTitle === AppointmentTypeNameEnum.TELEPHYSICAL)
    return `${AppointmentTypeNameEnum.TELEPHYSICAL} Care`;
  return modalTitle;
};
