import { IAppointmentDTO } from "components/forms/@dashboard/Appointments/AppointmentsList";
import { AppointmentTypeNameEnum } from "components/forms/ChooseYourService";
import { addMinutes } from "date-fns";
import { getItem } from "./localStorageUtils";
import {
  confirmBookingFormatDate,
  confirmBookingFormatHours,
} from "./transform/formatDate";

const checkVideoAppointmentLink = (
  date: string,
  lengthOfMeetInMinutes: number
): boolean => {
  const twentyMinutes = 1200000;
  const appointmentDateStart = new Date(date);
  const meetingEndTime = Number(
    addMinutes(appointmentDateStart, lengthOfMeetInMinutes)
  );
  const twentyMinBeforeStart = Number(appointmentDateStart) - twentyMinutes;

  const now = Number(new Date());

  return now > twentyMinBeforeStart && now < meetingEndTime;
};

const formatAppointmentTitle = (item: IAppointmentDTO) => {
  const formatDelimiter = ", ";
  const { contactType, date, hours } = formatAppointment(item);
  return contactType + formatDelimiter + date + formatDelimiter + hours;
};

const isPastAppointment = (
  date: string,
  lengthOfMeetInMinutes: number
): boolean => {
  const appointmentDateStart = new Date(date);
  const meetingEndTime = Number(
    addMinutes(appointmentDateStart, lengthOfMeetInMinutes)
  );
  return Number(new Date()) - meetingEndTime > 0;
};

const formatAppointment = (item: IAppointmentDTO) => {
  const contactType = isVideoAppointment(item)
    ? "Video appointment"
    : "Phone appointment";
  const date = confirmBookingFormatDate(item.scheduledDateByTimezone);
  const hours = confirmBookingFormatHours(item.scheduledDateByTimezone);

  return { contactType, date, hours };
};

const isVideoAppointment = (item: IAppointmentDTO) =>
  item.appointmentTypeFullName.split(" - ")[0] === "Zoom";

const parseAppointmentType = (fullAppointmentName: string) => {
  if (fullAppointmentName === AppointmentTypeNameEnum.TELEPHYSICAL)
    return `${fullAppointmentName} Care`;
  return fullAppointmentName;
};

type ContactType = "video" | "phone" | null;
type ContactTypeFormated = "Zoom" | "Phone";

const generateAppointmentTypeName = (
  contactType: ContactType,
  appointmentTypeName: AppointmentTypeNameEnum | null,
  isPcpConfirmed: boolean,
  isPcpFlow: boolean
) => {
  if (!contactType || !appointmentTypeName) return null;

  const contact: ContactTypeFormated =
    contactType === "video" ? "Zoom" : "Phone";
  const delimiter = " - ";
  const isPCP = isPcpConfirmed || isPcpFlow ? `${delimiter}PCP` : "";

  return `${
    contact + delimiter
  }Cloudwell${delimiter}${appointmentTypeName}${isPCP}`;
};

const readStripeBookingData = async () => {
  const bookingData = getItem("stripe_session_id");
  if (!bookingData) return Promise.reject(Error("Booking data missing"));
  delete bookingData.session_id;

  return bookingData;
};

export {
  checkVideoAppointmentLink,
  formatAppointmentTitle,
  isVideoAppointment,
  isPastAppointment,
  formatAppointment,
  parseAppointmentType,
  generateAppointmentTypeName,
  readStripeBookingData,
};
