import { useEffect, useRef, useState } from "react";
import userService from "services/userService";
import { MainContainer } from "@minchat/react-chat-ui";
import { AxiosResponse } from "axios";
import MessageType from "@minchat/react-chat-ui/dist/MessageType";
import { tr } from "date-fns/locale";
import styles from "./styles.module.scss";

export interface IChatMessageDTO {
  user: {
    id: string;
    name: string;
  };
  text: string;
}
function delay(ms: number) {
  // eslint-disable-next-line
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function Chat() {
  const [chatMessages, setChatMessages] = useState<MessageType[]>([]);
  const chatMessagesRef = useRef(chatMessages);
  const [chatMessagesLoading, setChatMessagesLoading] =
    useState<boolean>(false);
  const [chatMessagesTyping, setChatMessagesTyping] = useState<boolean>(false);
  const [chatMessageDataError, setChatMessageDataError] =
    useState<boolean>(false);
  // eslint-disable-next-line

  const fetchMessages = async (): Promise<MessageType[]> => {
    return await userService
      .getAllMessages()
      .then(async ({ data }: AxiosResponse<MessageType[]>) => {
        return data;
      });
  };

  useEffect(() => {
    fetchMessages().then((messages: MessageType[]) => {
      const updatedProvider = messages.map((item) => {
        return { ...item, user: { ...item.user, name: "Cloudwell Health" } };
      });
      setChatMessages(updatedProvider);
    });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      fetchMessages().then(async (messages: MessageType[]) => {
        if (messages.length > chatMessages.length) {
          setChatMessagesTyping(true);
          await delay(5000);
          const updatedProvider = messages.map((item) => {
            return {
              ...item,
              user: { ...item.user, name: "Cloudwell Health" },
            };
          });
          setChatMessages(updatedProvider);
          setChatMessagesTyping(false);
        }
      });
    }, 300000);

    return () => {
      clearInterval(timer);
    };
  }, [chatMessages]);
  const onSendMessage = (body: string) => {
    userService.sendMessage(body);
    setChatMessages((prevState) => {
      const newMessage = {
        user: { id: "patient", name: "patient" },
        text: body,
      };
      if (prevState) {
        return [...prevState, newMessage];
      }
      return [newMessage];
    });
  };
  return (
    <div
      style={{ height: "85%", maxHeight: "500px" }}
      className={styles.myProfilePaddingWrapper}
    >
      <MainContainer
        mobileView={true}
        inbox={{
          onScrollToBottom: () => {},
          themeColor: "#6ea9d7",
          conversations: [
            {
              id: "1",
              title: "With Provider",
            },
          ],
          loading: false,
          onConversationClick: () => console.log("onChat click"),
          selectedConversationId: "1",
        }}
        selectedConversation={{
          showBack: false,
          showTypingIndicator: chatMessagesTyping,
          loading: chatMessagesLoading,
          themeColor: "#6ea9d7",
          messages: chatMessages,
          header: "Cloudwell Health",
          showHeader: false,
          currentUserId: "patient",
          onSendMessage,
          onBack: () => {},
          showAttachButton: false,
        }}
      />
    </div>
  );
}

export default Chat;
