import { useEffect, useState } from "react";
import { ReactComponent as ProfileIcon } from "assets/images/stepOneIcon.svg";
import { ReactComponent as MessageIcon } from "assets/images/stepTwoIcon.svg";
import { ReactComponent as HomeIcon } from "assets/images/homeIcon.svg";
import { ReactComponent as AddIcon } from "assets/images/addIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendarIcon.svg";
import { ReactComponent as HeartIcon } from "assets/images/heartIcon.svg";
import { ReactComponent as ClockIcon } from "assets/images/clockIcon.svg";
import { ReactComponent as PaymentIcon } from "assets/images/paymentIcon.svg";
import TagManager from "react-gtm-module";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface IStepperProps {
  steps: Array<{ stepId: number; isActive: boolean }>;
  activeStep: number;
  form: string;
}

function Stepper({ steps, activeStep, form }: IStepperProps) {
  const [lastCompletedStep, setLastCompletedStep] = useState<number>(0);

  useEffect(() => {
    if (activeStep - 1 > lastCompletedStep) setLastCompletedStep(activeStep);

    const tagManagerArgs = {
      dataLayer: {
        event: "pageview",
        page: {
          url: `${document.documentURI}-${activeStep}`,
          title: document.title,
        },
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [activeStep]);

  return (
    <div className={styles.stepper_wrapper}>
      <div className={styles.stepper_circlesWrapper}>
        {steps.map(({ stepId, isActive }) => {
          const isCompleted = lastCompletedStep >= stepId;
          return (
            <StepCircle
              key={stepId}
              stepId={stepId}
              isActive={isActive}
              isCompleted={isCompleted}
            />
          );
        })}
      </div>
      {getStepIcon(form, activeStep)}
    </div>
  );
}

export default Stepper;

interface StepCircleProps {
  stepId: number;
  isActive: boolean;
  isCompleted: boolean;
}

export const StepCircle = ({
  stepId,
  isActive,
  isCompleted,
}: StepCircleProps) => {
  const classes = classNames({
    [styles.stepper_singleCircle_active]: isActive,
    [styles.stepper_singleCircle_completed]: isCompleted,
    [styles.stepper_singleCircle]: !isActive && !isCompleted,
  });

  return (
    <div
      className={classNames({
        [styles.stepper_singleCircle_active_outline]: isActive,
      })}
    >
      <div className={classes}>{stepId}</div>
    </div>
  );
};

const getStepIcon = (form: string, stepId: number) => {
  if (form === "register") {
    if (stepId === 1) return <ProfileIcon />;
    if (stepId === 2) return <MessageIcon />;
    if (stepId === 3) return <HomeIcon />;
  }

  if (form === "pcp") {
    if (stepId === 1) return <AddIcon />;
    if (stepId === 2) return <CalendarIcon />;
    if (stepId === 3) return <HeartIcon />;
    if (stepId === 4) return <ClockIcon />;
    if (stepId === 5) return <PaymentIcon />;
  }
  return <ProfileIcon />;
};
