import { useEffect, useState } from "react";
import Dropdown from "components/ui/Dropdown";
import InputField from "components/ui/InputField";
import FileUpload from "components/shared/FileUpload";
import insuranceProviders from "utils/insuranceProviders";
import client, { Events } from "services/EventEmitter";
import { AxiosResponse } from "axios";
import { ReactComponent as InsuranceFront } from "assets/images/insuranceFront.svg";
import { ReactComponent as InsuranceBack } from "assets/images/insuranceBack.svg";
import { ReactComponent as IdFront } from "assets/images/idFront.svg";
import { ReactComponent as IdBack } from "assets/images/idBack.svg";
import {
  DocumentType,
  fetchUserInsurance,
  InsuranceDTORequest,
  InsuranceDTOResponse,
  selectInsuranceData,
  updateInsuranceData,
} from "redux/insurance.slice";
import {
  IDocument,
  IDocumentDTO,
  prepareDocumentsForUpload,
} from "components/forms/Payment";
import Modal from "components/shared/Modal";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  VALIDATE_INSURANCE_PROVIDER_SELECTED,
  VALIDATE_INSURANCE_NUMBER_SELECTED,
  VALIDATE_INSURANCE_REGISTERED_ADDRESS,
} from "utils/validation/constants";
import useValidate from "utils/validation";
import Button from "components/ui/Button";
import { httpPost } from "services/httpClient";
import { insurance } from "routes/paths";
import { fetchProfileDocuments } from "redux/user.slice";
import userService from "services/userService";
import { ReactComponent as LayDocumentIcon } from "assets/images/layDocumentIcon.svg";
import { ReactComponent as FocusPhotoIcon } from "assets/images/focusPhotoIcon.svg";
import { ReactComponent as LightPhotoIcon } from "assets/images/lightPhotoIcon.svg";
import styles from "./styles.module.scss";

function InsuranceDetails({ handleClose }: { handleClose: Function }) {
  const dispatch = useAppDispatch();
  const existingInsuranceData = useAppSelector(selectInsuranceData);

  const [insuranceProvider, setInsuranceProvider] = useState<string>("");
  const [insuranceNumber, setInsuranceNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  const [showSaveLoader, setShowSaveLoader] = useState<boolean>(false);
  const [infoModalOpened, setInfoModalOpened] = useState<boolean>(false);

  const [frontInsuranceId, setFrontInsuranceId] = useState<string>("");
  const [frontInsuranceIdFile, setFrontInsuranceIdFile] =
    useState<IDocument | null>(null);
  const [backInsuranceIdFile, setBackInsuranceIdFile] =
    useState<IDocument | null>(null);
  const [backInsuranceId, setBackInsuranceId] = useState<string>("");
  const [frontId, setFrontId] = useState<string>("");
  const [frontIdFile, setFrontIdFile] = useState<IDocument | null>(null);
  const [backId, setBackId] = useState<string>("");
  const [backIdFile, setBackIdFile] = useState<IDocument | null>(null);

  const [isProviderSelected, providerErrors] = useValidate(
    insuranceProvider,
    VALIDATE_INSURANCE_PROVIDER_SELECTED
  );

  const [isInsuranceNumberValid, insuranceNumberErrors] = useValidate(
    insuranceNumber,
    VALIDATE_INSURANCE_NUMBER_SELECTED
  );

  const [isInsuranceAddressValid, insuranceAddressErrors] = useValidate(
    address,
    VALIDATE_INSURANCE_REGISTERED_ADDRESS
  );

  useEffect(() => {
    setHaveErrors(!isProviderSelected || !isInsuranceNumberValid);
  }, [insuranceProvider, insuranceNumber, address]);

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const handleChangeProvider = (selected: string) => {
    setInsuranceProvider(selected);
  };

  const handleChangeInsuranceNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInsuranceNumber(e.target.value);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const closeInfoModal = () => setInfoModalOpened(false);

  const createOrGetInsuranceProvider = async (): Promise<
    AxiosResponse<InsuranceDTOResponse> | { data: { id: string } }
  > => {
    if (
      existingInsuranceData &&
      existingInsuranceData?.provider?.name === insuranceProvider &&
      existingInsuranceData?.membershipId === insuranceNumber &&
      existingInsuranceData?.registeredAddress === address
    )
      return Promise.resolve({
        data: { id: existingInsuranceData.id },
      });

    const insuranceDto: InsuranceDTORequest = {
      membershipId: insuranceNumber,
      provider: insuranceProvider,
      registeredAddress: address,
      rank: 1,
    };
    return httpPost(insurance, insuranceDto).then(
      (res: AxiosResponse<InsuranceDTOResponse>) =>
        new Promise((resolve) => {
          dispatch(updateInsuranceData(res.data));
          resolve(res);
        })
    );
  };

  const handleSaveData = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    setShowSaveLoader(true);

    createOrGetInsuranceProvider()
      .then(
        (res: AxiosResponse<InsuranceDTOResponse> | { data: { id: string } }) =>
          prepareDocumentsForUpload(
            res.data.id,
            frontIdFile,
            backIdFile,
            frontInsuranceIdFile,
            backInsuranceIdFile
          )
      )
      .then((documentArray: IDocumentDTO[]) => {
        return Promise.allSettled(
          documentArray.map((document) => userService.uploadDocument(document))
        );
      })
      .catch((err) => {
        console.log("error while uploading documents");
      })
      .finally(() => {
        fetchInsurance().finally(() => setShowSaveLoader(false));
      });
  };

  const fetchInsurance = () => {
    return dispatch(fetchUserInsurance())
      .unwrap()
      .then(({ data }: AxiosResponse) => {
        if (data.length) {
          const insuranceFrontImagePreview = data[0].documents.find(
            (doc: any) => doc.side === 1
          )?.url;
          const insuranceBackImagePreview = data[0].documents.find(
            (doc: any) => doc.side === 2
          )?.url;

          setFrontInsuranceId(insuranceFrontImagePreview);
          setBackInsuranceId(insuranceBackImagePreview);

          setInsuranceProvider(data[0].provider.name);
          setInsuranceNumber(data[0].membershipId);
          setAddress(data[0].registeredAddress);
        }
      });
  };

  const fetchProfileDocs = async () => {
    await dispatch(fetchProfileDocuments())
      .unwrap()
      .then(({ data }: AxiosResponse) => {
        if (data.length) {
          const idFront = data.find((doc: any) => doc.side === 1)?.url;
          const idBack = data.find((doc: any) => doc.side === 2)?.url;

          setFrontId(idFront);
          setBackId(idBack);
        }
      });
  };

  useEffect(() => {
    fetchInsurance();
    fetchProfileDocs();

    return () => {
      client.emit(Events.ENABLE_SCROLL, 0);
    };
  }, []);

  return (
    <div>
      <Dropdown
        initialValue={insuranceProvider}
        label="Choose insurance provider"
        classes={styles.insuranceProviderStyle}
        options={insuranceProviders}
        onChange={handleChangeProvider}
        error={providerErrors[0]}
        showError={showErrors}
      />
      <InputField
        value={insuranceNumber}
        onChange={handleChangeInsuranceNumber}
        error={insuranceNumberErrors[0]}
        showError={showErrors}
        label="Membership ID or insurance number"
        id="insurnaceNumber"
      />
      {/* <InputField
        value={address}
        onChange={handleChangeAddress}
        error={insuranceAddressErrors[0]}
        showError={showErrors}
        label="Registered address"
        classes={styles.addressFieldInput}
        id="address"
      /> */}

      <div className={styles.hrLine} />

      <p className={styles.title}>Insurance documents</p>
      <p className={styles.uploadDescription}>
        Supported file types are PNG and JPEG. <span>&nbsp;</span>
        <span
          onClick={() => setInfoModalOpened(true)}
          onKeyPress={() => console.log("Upload help...")}
          role="button"
          tabIndex={0}
        >
          {" "}
          Need some help with it?
        </span>
      </p>

      {/* File uploads */}
      <div className={styles.tabContent_wrapper}>
        <FileUpload
          id={1}
          fileUrl={frontInsuranceId}
          setFileUrl={setFrontInsuranceId}
          title={
            <>
              Upload <b>front</b> of insurance ID card
            </>
          }
          icon={<InsuranceFront />}
          uploadedText="Front of insurance ID added!"
          classes={styles.fieldUploadStyle}
          error="Please upload the requested document"
          showError={false}
          fileName="insuranceFront"
          fileData={frontInsuranceIdFile}
          setFileData={setFrontInsuranceIdFile}
          type={DocumentType.INSURANCE}
          side={1}
        />
        <FileUpload
          id={2}
          fileUrl={backInsuranceId}
          setFileUrl={setBackInsuranceId}
          title={
            <>
              Upload <b>back</b> of insurance ID card
            </>
          }
          icon={<InsuranceBack />}
          uploadedText="Back of insurance ID added!"
          error="Please upload the requested document"
          showError={false}
          fileName="insuranceBack"
          fileData={backInsuranceIdFile}
          setFileData={setBackInsuranceIdFile}
          type={DocumentType.INSURANCE}
          side={2}
        />
        <FileUpload
          id={3}
          fileUrl={frontId}
          setFileUrl={setFrontId}
          title={
            <>
              Upload <b>front</b> of picture ID
            </>
          }
          description="(e.g. a passport or driving license)"
          icon={<IdFront />}
          uploadedText="Front of ID card added!"
          error="Please upload the requested document"
          showError={false}
          fileName="idFront"
          fileData={frontIdFile}
          setFileData={setFrontIdFile}
          type={DocumentType.PROFILE}
          side={1}
        />
        <FileUpload
          id={4}
          fileUrl={backId}
          setFileUrl={setBackId}
          title={
            <>
              Upload <b>back</b> of picture ID
            </>
          }
          description="Optional – for picture ID with information on the reverse"
          icon={<IdBack />}
          uploadedText="Back of ID card added!"
          error="Please upload the requested document"
          showError={false}
          fileName="idBack"
          fileData={backIdFile}
          setFileData={setBackIdFile}
          type={DocumentType.PROFILE}
          side={2}
        />
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          text="Cancel"
          size="small"
          color="outlined_blue"
          handleClick={handleClose}
        />
        <Button
          text="Save changes"
          size="small"
          color="blue"
          handleClick={handleSaveData}
          isLoading={showSaveLoader}
        />
      </div>
      {/* Modal */}
      <Modal
        isOpened={infoModalOpened}
        handleClose={closeInfoModal}
        title="Uploading your insurance documents"
        classes={styles.modalStyle}
        buttons={
          <Button
            text="Ok, got it"
            color="blue"
            size="small"
            handleClick={closeInfoModal}
          />
        }
      >
        <>
          <p className={styles.modalDescription}>
            You can share you insurance documents by photographing them with
            your smartphone.
          </p>
          <div className={styles.modalItem}>
            <LayDocumentIcon />
            <span>
              Don’t obscure any info on the document. Lay the document down flat
              rather than holding it.
            </span>
          </div>
          <div className={styles.modalItem}>
            <FocusPhotoIcon />
            <span>
              Ensure the document is fully within your <br />
              camera view finder. Ensure that your photo is <br />
              in focus, and has no glare.
            </span>
          </div>
          <div className={styles.modalItem}>
            <LightPhotoIcon />
            <span>
              Ensure your document photo is taken in good light - natural
              sunlight if possible.
            </span>
          </div>
        </>
      </Modal>
    </div>
  );
}

export default InsuranceDetails;
