import { useState, useEffect, MouseEvent, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import { saveSecondPcpStep, selectSecondPcpStep } from "redux/pcpFlow.slice";
import { ReactComponent as VideoIcon } from "assets/images/videoIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/images/phoneIcon.svg";
import Button from "components/ui/Button";
import InputField from "components/ui/InputField";
import useValidate from "utils/validation";
import OptionsWrapper, { Option } from "components/shared/OptionsWrapper";
import { VALIDATE_PHONE } from "utils/validation/constants";
import {
  formatToPhone,
  unwrapPhoneNumber,
} from "utils/transform/formatToPhone";
import client, { Events } from "services/EventEmitter";
import {
  saveSecondBookingAppointmentStep,
  selectFirstBookingAppointmentStep,
  selectSecondBookingAppointmentStep,
} from "redux/bookAppointmentFlow.slice";
import {
  PatientTagsEnum,
  selectIsPcpConfirmed,
  selectPhoneNumber,
  selectTag,
} from "redux/user.slice";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";
import { AppointmentTypeNameEnum } from "../ChooseYourService";

interface Props {
  nextStep: (stepId: number) => void;
  bookingAppointment?: boolean;
}

const VIDEO = "video";
const PHONE = "phone";
type MeetType = typeof VIDEO | typeof PHONE;

function AppointmentType({ nextStep, bookingAppointment = false }: Props) {
  const dispatch = useAppDispatch();
  const existingPhoneNumber = useAppSelector(selectPhoneNumber);
  const secondStep = useAppSelector(
    bookingAppointment
      ? selectSecondBookingAppointmentStep
      : selectSecondPcpStep
  );

  const [type, setType] = useState<MeetType>(
    secondStep.appointmentType || VIDEO
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(existingPhoneNumber);

  const [isPhoneValid, phoneErrors] = useValidate(phoneNumber, VALIDATE_PHONE);

  const phoneAllowed = useDisablePhoneChannel(bookingAppointment);

  const isVideo = type === VIDEO;
  const isPhone = type === PHONE;

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    if (!type) {
      setHaveErrors(true);
      return;
    }
    if (type === PHONE) setHaveErrors(!isPhoneValid);

    if (type === VIDEO) setHaveErrors(false);
  }, [isPhoneValid, type]);

  const handleSelectType = (event: MouseEvent<HTMLHeadingElement>) => {
    if (!phoneAllowed && event.currentTarget.id === PHONE) return;
    setType(event.currentTarget.id as MeetType);
  };

  const handleChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const unformatedPhoneNumber = unwrapPhoneNumber(e.target.value);
    setPhoneNumber(unformatedPhoneNumber);
  };

  const handleContinue = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    if (bookingAppointment)
      dispatch(
        saveSecondBookingAppointmentStep({
          appointmentPhone: phoneNumber,
          appointmentType: type,
        })
      );
    else
      dispatch(
        saveSecondPcpStep({
          appointmentType: type,
          appointmentPhone: phoneNumber,
        })
      );

    nextStep(3);
  };

  const phoneAdditionalInfo = !phoneAllowed ? (
    <p className={styles.phoneNotAllowed}>
      Sorry - phone appointments are not available for this appointment type
    </p>
  ) : isPhone ? (
    <div className={styles.phoneWrapper}>
      <InputField
        value={formatToPhone(phoneNumber)}
        onChange={handleChangePhoneNumber}
        error={phoneErrors[0]}
        showError={showErrors}
        label="Phone number"
        id="phoneNumber"
      />
    </div>
  ) : null;

  return (
    <>
      <h2 className="FormComponent-title">
        {bookingAppointment ? "Appointment type" : "Book appointment"}
      </h2>
      <p className="FormComponent-description">
        {bookingAppointment
          ? "Choose whether you’d like to speak to someone via video, or on the phone."
          : "As a new PCP patient, you must have an appointment with a medical professional to establish care even if you don’t have a current health concern."}
      </p>
      <p className={styles.typeLabel}>Appointment type*</p>
      <OptionsWrapper>
        <Option
          id={VIDEO}
          title="Video appointment"
          description="Chat via a secure video link"
          icon={<VideoIcon />}
          active={isVideo}
          handleSelect={handleSelectType}
          additionalInfo={null}
        />
        <Option
          id={PHONE}
          title="Phone appointment"
          description="Talk to a physician over the phone"
          icon={<PhoneIcon />}
          active={isPhone}
          disabled={!phoneAllowed}
          handleSelect={handleSelectType}
          additionalInfo={phoneAdditionalInfo}
        />
      </OptionsWrapper>
      <ActionsContainer>
        <Button
          text="Continue"
          descriptionText="Provide health concern"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
        />
      </ActionsContainer>
    </>
  );
}

export default AppointmentType;

const useDisablePhoneChannel = (bookingAppointment: boolean) => {
  const isPcpConfirmed = useAppSelector(selectIsPcpConfirmed);
  const firstBookingStep = useAppSelector(selectFirstBookingAppointmentStep);
  const selectedTag = useAppSelector(selectTag);
  // const isChild = useAppSelector(selectChildTag);

  const isTelephysical =
    firstBookingStep.name === AppointmentTypeNameEnum.TELEPHYSICAL;

  if (isTelephysical) return false;

  if (
    ((isPcpConfirmed ||
      !selectedTag || // UC-Active
      selectedTag === PatientTagsEnum.PCP_PENDING) && // PCP-pending
      isTelephysical) ||
    !bookingAppointment
    // isChild
  )
    return false;
  return true;
};
