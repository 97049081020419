import { useEffect } from "react";
import PageContainer from "components/layout/PageContainer";
import MainMenu from "components/layout/Header/MainMenu";
import Offer from "components/layout/Header/Offer";
import GuestHero from "components/shared/GuestHero";
import UserHero from "components/shared/UserHero";
import { httpGet } from "services/httpClient";
import useAuth from "hooks/useAuth";
import { Navigate, useNavigate } from "react-router-dom";

function Home(): JSX.Element {
  const { isAuth } = useAuth();

  const redirectTo = isAuth ? "/dashboard/appointments" : "/login";

  return <Navigate to={redirectTo} replace />;

  // return (
  //   <>
  //     <MainMenu />
  //     <Offer />
  //     {isAuth ? <UserHero /> : <GuestHero />}
  //     <PageContainer>
  //       <h1>Home</h1>
  //     </PageContainer>
  //   </>
  // );
}

export default Home;
