import { useState, ChangeEventHandler } from "react";
import { Link } from "react-router-dom";
import { resetPassword } from "routes/paths";
import ValidationError from "components/shared/ValidationError";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  description?: string | undefined;
  label: string;
  required?: boolean;
  error?: string | undefined;
  showError?: boolean;
  passwordError?: string | false;
  disabled?: boolean;
  classes?: any;
  lightLabel?: boolean;
  type?: FieldType;
  id: string;
  showReturnToSignUp?: boolean;
  hideForgotPasswordLink?: boolean;
}

type FieldType = "text" | "email" | "password";

export default function InputField({
  value,
  onChange,
  description,
  label,
  required = true,
  error,
  showError = false,
  passwordError = false,
  disabled = false,
  classes,
  lightLabel = false,
  type = "text",
  id,
  showReturnToSignUp = false,
  hideForgotPasswordLink = false,
}: Props) {
  const [fieldType, setFieldType] = useState<FieldType>(type);

  const togglePasswordView = () => {
    if (fieldType === "password") setFieldType("text");
    else setFieldType("password");
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const displayLabel = `${label}${required ? "*" : " (optional)"}`;

  // Icon used only for password field
  const showPasswordIcon =
    type !== "password" ? undefined : (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={togglePasswordView}
          onMouseDown={handleMouseDownPassword}
          edge="end"
          className={styles.visibilityButton}
        >
          {fieldType === "password" ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );

  const forgotPassword = fieldType === "password" &&
    !hideForgotPasswordLink && (
      <Link to={resetPassword} className={styles.sideLink} tabIndex={-1}>
        Forgot your password?
      </Link>
    );

  const returnToSignUp = showReturnToSignUp && (
    <Link to="/login" className={styles.sideLink}>
      Return to login
    </Link>
  );

  return (
    <div className={classNames([styles.inputFieldWrapper], [classes])}>
      <InputLabel
        htmlFor={id}
        className={classNames(styles.label, {
          [styles.lightLabel]: lightLabel,
        })}
      >
        {displayLabel}
        {forgotPassword}
        {returnToSignUp}
      </InputLabel>
      {description && <p className={styles.description}>{description}</p>}
      <InputBase
        value={value}
        onChange={handleChange}
        className={classNames(styles.inputField, {
          [styles.inputFieldError]: (showError && error) || passwordError,
          [styles.filledInputStyle]: value,
          disabledField: disabled,
        })}
        classes={{ disabled: "disabledInput" }}
        fullWidth
        id={id}
        disabled={disabled}
        type={fieldType}
        endAdornment={showPasswordIcon}
      />
      <ValidationError show={showError} error={error} />
    </div>
  );
}
